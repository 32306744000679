import {ISharepointListElement} from "../../src/dtos/SharepointList.interface";

export type TAction =
  | { type: 'loadApiData', payload: ISharepointListElement[] }
  | { type: 'loadExcelData', payload: { excelJournalistIds: string[], excelColumnsNumber: number }}
  | { type: 'filterListData', payload: { searchQuery: string, redactionSearchQuery: string, distributionTags: string[] }}
  | { type: 'selectJournalist', payload: string }
  | { type: 'markJournalistAsEntered', payload: string }

export interface IState {
  journalistsLists: ISharepointListElement[],
  distributionTags: string[],
  enteredJournalistsIds: string[],
  numberOfExcelColumns: number,

  filteredListData: ISharepointListElement[],
  selectedJournalistId: string,
}

export default function reducer(state: IState, action: TAction) {
  switch(action.type) {
    case 'loadApiData':
      // Load data received from API, map tags for filters
      let tempTags = action.payload
                .map((item) => item.fields.Kategorie_x0020_dystrybucyjne.map((field) => field.LookupValue))
                .flat(1)
                .sort();
      tempTags = tempTags.filter((item, index) => tempTags.indexOf(item) === index);
      tempTags.push("");
      return {...state, journalistsLists: action.payload, filteredListData: action.payload,  distributionTags: tempTags}
    case 'loadExcelData':
      return {...state, enteredJournalistsIds: action.payload.excelJournalistIds, numberOfExcelColumns: action.payload.excelColumnsNumber}
    case 'filterListData':
      const filteredListData = state.journalistsLists.filter((item) =>
          (item.fields.FullName.toLowerCase().includes(action.payload.searchQuery.toLowerCase()) &&
            item.fields.Redakcja.map((el) => el.LookupValue)
              .join(" ")
              .toLowerCase()
              .includes(action.payload.redactionSearchQuery.toLowerCase()) &&
            action.payload.distributionTags[0] === "") ||
          item.fields.Kategorie_x0020_dystrybucyjne.map((el) => el.LookupValue).some((el) =>  action.payload.distributionTags.includes(el))
        );
      return {...state, filteredListData: filteredListData}
    case 'selectJournalist':
      return { ...state, selectedJournalistId: action.payload }
    case 'markJournalistAsEntered':
        return { ...state, enteredJournalistsIds: [...state.enteredJournalistsIds, action.payload] }
  }
}

export const initialState: IState = {
  journalistsLists: [],
  distributionTags: [],
  enteredJournalistsIds: [],
  numberOfExcelColumns: 0,
  filteredListData: [],
  selectedJournalistId: '',
}