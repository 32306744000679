import {MessageBarBody, MessageBarTitle} from "@fluentui/react-components";
import {MessageBar, MessageBarType} from "office-ui-fabric-react";
import * as React from "react";

export default function ErrorMessage(props: { title: string }) {
  return <div className="ms-welcome">
    <MessageBar messageBarType={MessageBarType.error}>
      <MessageBarBody>
        <MessageBarTitle>{props.title}</MessageBarTitle>
      </MessageBarBody>
    </MessageBar>
  </div>
    }