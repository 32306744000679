import * as React from "react";
import { Header } from "./appPartials/Header";
import { Search } from "./appPartials/Search";
import { useAppInit } from "../../../utilities/hooks/useAppInit";
import ErrorMessage from "./partials/ErrorMessage";
import ProgressSpinner from "./partials/ProgressSpinner";
import {useReducer} from "react";
import reducer, {initialState, IState, TAction} from "../../../utilities/reducers/reducer";
import { StateContext, StateDispatchContext } from "../../../utilities/context/context";
import {Divider, makeStyles} from "@fluentui/react-components";
import FilteredList from "./appPartials/FilteredList";
import Footer from "./appPartials/Footer";

// Deploy: https://learn.microsoft.com/en-us/office/dev/add-ins/publish/publish-add-in-vs-code
// Based on: https://github.com/OfficeDev/Office-Add-in-samples/blob/main/Samples/auth/Office-Add-in-Microsoft-Graph-React/src/components/App.tsx

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    padding: '1rem 1rem 0',
    zIndex: 100,
    borderBottom: '1px solid #ddd',
  },
});

export default function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const appState = useAppInit(dispatch);

  switch (appState) {
    case "starting":
      return <ProgressSpinner title="Ładowanie aplikacji" />;
    case "logging":
      return <ProgressSpinner title="Logowanie" />;
    case "loginError":
      return <ErrorMessage title="Wystąpił błąd przy próbie zalogowania użytkownika." />;
    case "loading":
      return <ProgressSpinner title="Pobieranie danych" />;
    case "downloadDataError":
      return <ErrorMessage title="Wystąpił błąd przy próbie pobrania danych." />;
    case "loadExcelDataError":
      return <ErrorMessage title="Wystąpił błąd przy wczytywaniu danych z tabeli dziennikarzy." />;
    case "loadingError":
      return <ErrorMessage title="Wystąpił błąd przy wczytywaniu danych." />;
    case "ready":
      return <ReadyApp state={state} dispatch={dispatch} />;
    default:
      return <ErrorMessage title="Wystąpił nieznany błąd." />;
  }
}

function ReadyApp(props: { state: IState; dispatch: React.Dispatch<TAction> }) {
  const styles = useStyles();
  return (
    <div className="ms-welcome">
      <StateContext.Provider value={props.state}>
        <StateDispatchContext.Provider value={props.dispatch}>
          <div className={styles.root}>
            <Header />
            <Search />
          </div>
          <Divider />
          <FilteredList />
          <Footer />
        </StateDispatchContext.Provider>
      </StateContext.Provider>
    </div>
  );
}
