import {useContext} from "react";
import {StateDispatchContext} from "../context/context";

export function useReloadExcelData() {
    const dispatch = useContext(StateDispatchContext);
    return function() {
        Excel.run(async (context) => {
            try {
                const sheet = context.workbook.worksheets.getItem("Robocza lista gości");
                const table = sheet.tables.getItem("Lista");

                // Load the necessary properties
                table.load("rows, columns");
                await context.sync();

                // Get journalists ids that are already in a table
                const journalistsIds = table.rows.items.map(row => String(row.values[0][0]));

                dispatch({
                    type: 'loadExcelData',
                    payload: {
                        excelJournalistIds: journalistsIds,
                        excelColumnsNumber: table.columns.count,
                    }
                });
            } catch (error) {
                throw Error();
            }
        });
    }
}