import {useContext} from "react";
import {StateContext, StateDispatchContext} from "../context/context";

export default function useInsertToTable() {
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);
  let rowData = [[state.selectedJournalistId]];

  for (let i = 1; i < state.numberOfExcelColumns; i++) {
    rowData[0].push(null);
  }

  if (state.enteredJournalistsIds.includes(state.selectedJournalistId)) {
    return function () {};
  }

  return function() {
    dispatch({
        type: 'markJournalistAsEntered',
        payload: state.selectedJournalistId
    });
    Excel.run(async (context) => {
      await context.sync();
      const sheet = context.workbook.worksheets.getItem("Robocza lista gości");
      sheet.load("protection/protected");
      const table = sheet.tables.getItem("Lista");

      await context.sync();
      sheet.protection.unprotect();
      table.rows.add(
        null,
        rowData,
        true
      );

      sheet.protection.protect({
        allowAutoFilter: true,
        allowDeleteColumns: false,
        allowDeleteRows: true,
        allowFormatRows: true,
        allowInsertRows: true,
        allowFormatColumns: true,
        allowFormatCells: true,
        allowInsertColumns: false,
        allowSort: true,
        allowInsertHyperlinks: false,
        allowPivotTables: false,
      });
      await context.sync();
    });
  }
}