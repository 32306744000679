import * as React from "react";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { ISharepointListElement } from "../../src/dtos/SharepointList.interface";
import { TAction } from "../reducers/reducer";

/* globals OfficeRuntime */
/**
 * Hook with functions initialising application.
 */
type TApplicationState =
  | "starting"
  | "logging"
  | "loginError"
  | "loading"
  | "downloadDataError"
  | "loadExcelDataError"
  | "loadingError"
  | "ready";


export function useAppInit(dispatch: React.Dispatch<TAction>) {
  const [state, setState] = useState<TApplicationState>("starting");
  const [dataState, setDataState] = useState<"idle" | "loading" | "error" | "success">("idle");
  const [excelState, setExcelState] = useState<"idle" | "loading" | "error" | "success">("idle");
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
      setState("logging");
      OfficeRuntime.auth
        .getAccessToken({
          allowSignInPrompt: true,
          allowConsentPrompt: true,
          forMSGraphAccess: true,
        })
        .then((token: string) => {
          setToken(token);
          setState("loading");
        })
        .catch((error: any) => {
          setState("loginError");
          Sentry.captureException(error);
        });
  }, []);

  useEffect(() => {
    if (token !== null) {
      setDataState("loading");
      fetch("https://relations-social-media-updater.azurewebsites.net/zapraszalnik", {
        headers: {
          TenantId: "4c052e42-013d-4676-9f4b-b87bf5db00e8",
          AccessToken: token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .catch((error): any => {
          setDataState("error");
          Sentry.captureException(error);
        })
        .then((data: ISharepointListElement[]) => {
          dispatch({
            type: "loadApiData",
            payload: data,
          });
          setDataState("success");
        });
    }
  }, [token]);

  useEffect(() => {
    if (token !== null) {
      setExcelState("loading");
      Excel.run(async (context) => {
        try {
          const sheet = context.workbook.worksheets.getItem("Robocza lista gości");
          const table = sheet.tables.getItem("Lista");

          // Load the necessary properties
          table.load("rows, columns");
          await context.sync();

          // Get journalists ids that are already in a table
          const journalistsIds = table.rows.items.map(row => String(row.values[0][0]));

          dispatch({
            type: 'loadExcelData',
            payload: {
              excelJournalistIds: journalistsIds,
              excelColumnsNumber: table.columns.count,
            }
          });
        } catch (error) {
          throw Error();
        }
      }).then(() => {
        setExcelState("success");
      }).catch(() => {
        setExcelState("error");
      });
    }
  }, [token]);

  useEffect(() => {
    if (dataState === "success" && excelState === "success") {
      setState("ready");
    } else if (dataState === "error" && excelState === "error") {
      setState("loadingError");
    } else if (dataState === "error") {
      setState("downloadDataError");
    } else if (excelState === "error") {
      setState("loadExcelDataError");
    }
  }, [state, excelState, dataState]);

  return state;
}
