import * as React from "react";
import { Button, Divider, makeStyles } from "@fluentui/react-components";
import { Settings24Regular } from "@fluentui/react-icons";
import DrawerInline from "./DrawerInline";
import useInsertToTable from "../../../../utilities/hooks/useInsertToTable";
import {useReloadExcelData} from "../../../../utilities/hooks/useReloadExcelData";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "calc(100% - 2rem)",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "1rem",
    backgroundColor: "white",
  },
  mr: {
    marginRight: "1rem",
  },
  mb: {
    marginBottom: "1rem",
  },
});

export default function Footer() {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const insertFunction = useInsertToTable();
  const refreshFunction = useReloadExcelData();

  return (
    <div className={styles.root}>
      <Divider className={styles.mb} />
      <Button shape="square" appearance="primary" className={styles.mr} onClick={insertFunction}>
        Dodaj
      </Button>
      <Button shape="square" appearance="secondary" className={styles.mr} onClick={refreshFunction}>
        Odśwież
      </Button>
      <Button shape="circular" icon={<Settings24Regular />} onClick={() => setOpen((open) => !open)} />
      <DrawerInline open={open} setOpen={setOpen} position="bottom" />
    </div>
  );
}
