import * as React from "react";
import { Divider, Subtitle2, Text, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { ISharepointListElement } from "../../../dtos/SharepointList.interface";
import {useContext} from "react";
import {StateContext, StateDispatchContext} from "../../../../utilities/context/context";
import useInsertToTable from "../../../../utilities/hooks/useInsertToTable";

interface EntryProps {
  object: ISharepointListElement;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    ...shorthands.padding("1rem"),

    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  selected: {
    backgroundColor: "#0078D4",
    color: "white",
    "&:hover": {
      backgroundColor: "#005A9F",
    },
  },
  used: {
    backgroundColor: "#5c5c5c",
    color: "white",
    '&:hover': {
      backgroundColor: "#5c5c5c",
    }
  },
  notSelected: {},
});

export default function Entry(props: EntryProps) {
  const styles = useStyles();
  const insertFunction = useInsertToTable();

  // Global app state
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);

  const clickFunction = () => {
    if (state.selectedJournalistId === props.object.id && !state.enteredJournalistsIds.includes(props.object.id)) {
      insertFunction();
    } else {
      dispatch({
        type: 'selectJournalist',
        payload: props.object.id,
      })
    }
  };

  const classToUse = function () {
    if (state.selectedJournalistId === props.object.id) {
      return mergeClasses(styles.root, styles.selected);
    } else if (state.enteredJournalistsIds.includes(props.object.id)) {
      return mergeClasses(styles.root, styles.used);
    } else {
      return mergeClasses(styles.root, styles.notSelected);
    }
  }

  return (
    <>
      <div
        className={classToUse()}
        onClick={clickFunction}
      >
        <Subtitle2>{props.object.fields.FullName}</Subtitle2>
        <Text>
          <b>Redakcja:</b> {props.object.fields.Redakcja.map((el) => `${el.LookupValue}, `)}
        </Text>
        <Text>
          <b>Kategoria:</b> {props.object.fields.Kategorie_x0020_dystrybucyjne.map((el) => `${el.LookupValue}, `)}
        </Text>
      </div>
      <Divider />
    </>
  );
}
