import * as React from "react";
import { Dropdown, Input, makeStyles, Option } from "@fluentui/react-components";
import {useContext, useEffect} from "react";
import {StateContext, StateDispatchContext} from "../../../../utilities/context/context";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  dropdown: {
    marginTop: ".5rem",
  },
});

export const Search = () => {
  const styles = useStyles();
  // Global app state
  const state = useContext(StateContext);
  const dispatch = useContext(StateDispatchContext);

  // Local fields state
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [redactionSearchQuery, setRedactionSearchQuery] = React.useState<string>("");
  const [distributionTags, setDistributionTags] = React.useState<string[]>([""]);
  const [value, setValue] = React.useState<string>("");

  // Use Effect to handle search with dispatch
  useEffect(() => {
    dispatch({
      type: 'filterListData',
      payload: { searchQuery, redactionSearchQuery, distributionTags },
    });
  }, [searchQuery, redactionSearchQuery, distributionTags]);

  const onOptionSelect = (_ev, data) => {
    setDistributionTags(data.selectedOptions);
    setValue(data.optionText ?? "");
  };

  return (
    <div className={styles.root}>
      <Input
        id="search"
        appearance="outline"
        value={searchQuery}
        onChange={(_ev, data) => setSearchQuery(data?.value ?? " ")}
        placeholder="Imię, Nazwisko (minimum 3 znaki)"
      />
      <Input
        id="redaction"
        appearance="outline"
        value={redactionSearchQuery}
        onChange={(_ev, data) => setRedactionSearchQuery(data?.value ?? " ")}
        placeholder="Redakcja (minimum 3 znaki)"
        className={styles.dropdown}
      />
      <Dropdown
        id={`tags`}
        className={styles.dropdown}
        value={value}
        selectedOptions={distributionTags}
        onOptionSelect={onOptionSelect}
        placeholder="Wybierz kategorię dystrybucyjną"
      >
        {state.distributionTags.map((tag) => (
          <Option text={tag} value={tag} key={tag}>
            {tag}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};
