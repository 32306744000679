import React from "react";
import {useContext} from "react";
import {StateContext} from "../../../../utilities/context/context";
import Entry from "./Entry";
import {makeStyles} from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    marginTop: '208px',
  },
});

export default function FilteredList() {
  const state = useContext(StateContext);
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {state.filteredListData.map((item) => (
        <Entry
          object={item}
          key={item.id}
        />
      ))}
    </div>
  );
}