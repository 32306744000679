import * as React from "react";
import { DrawerBody, DrawerHeader, DrawerHeaderTitle, InlineDrawer, Button, Switch } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

/* global Office */

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  position: "bottom" | "end" | "start";
}

export default function DrawerInline(props: Props) {
  const { open, setOpen, position } = props;

  const autoshowOption = () => {
    Office.context.document.settings.set(
      "Office.AutoShowTaskpaneWithDocument",
      !Office.context.document.settings.get("Office.AutoShowTaskpaneWithDocument")
    );
    Office.context.document.settings.saveAsync();
  };

  return (
    <InlineDrawer open={open} position={position}>
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button appearance="subtle" aria-label="Close" icon={<Dismiss24Regular />} onClick={() => setOpen(false)} />
          }
        >
          Ustawienia
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        <Switch
          label="Zawsze otweiraj na tym pliku"
          checked={Office.context.document.settings.get("Office.AutoShowTaskpaneWithDocument")}
          onChange={autoshowOption}
        />
      </DrawerBody>
    </InlineDrawer>
  );
}
