import * as React from "react";
import { Text } from "@fluentui/react-components";

export const Header = () => {
  return (
    <Text as="h1" size={500}>
      Wyszukaj dziennikarza do dodania
    </Text>
  );
};
